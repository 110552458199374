export default {
    methods: {
        getClinicDetail: function(data, callback) {
            this.$store.dispatch('getClinicDetail', data)
            .then((rs)=>{
                callback && callback(data, null)
            }).catch((error)=>{
                callback && callback(null, error)
            })
        }
    }
}