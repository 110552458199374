import { render, staticRenderFns } from "./ClinicDetail.vue?vue&type=template&id=3b82078a&scoped=true"
import script from "./ClinicDetail.vue?vue&type=script&lang=js"
export * from "./ClinicDetail.vue?vue&type=script&lang=js"
import style0 from "./ClinicDetail.vue?vue&type=style&index=0&id=3b82078a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b82078a",
  null
  
)

export default component.exports