<template>
    <van-pull-refresh v-model="isRefresh" @refresh="onRefresh">
        <div id="clinic-detail" style="min-height: 100vh;" ref="listView">
            <div class="clinic-info" v-if="detail">
                <div class="row">
                    <img class="icon-clinic" :src="detail.logo">
                    <div class="col">
                        <h1 class="name" v-text="detail.clinicname"></h1>
                        <p class="icon-p area" v-text="`${detail.arealevel1name}-${detail.arealevel2name}`"></p>
                        <p class="icon-p tel" v-text="`${detail.contacttel || ''}${detail.contacttelother ? `（${detail.contacttelother}）`: ''}`"></p>
                        <p class="icon-p email" v-if="detail.workdays && '1,2,3,4,5,6,7'.indexOf(detail.workdays) == -1" v-text="`工作時間: ${detail.workdays.split(',').map(d=>weeks[d-1]).join(',')} ${detail.workstarttime.substring(0, 5)}-${detail.workendtime.substring(0, 5)}${detail.worktimeremark && `(${detail.worktimeremark})`}`"></p>
                        <p class="icon-p email" v-if="detail.workdays && '1,2,3,4,5,6,7'.indexOf(detail.workdays) != -1" v-text="`${detail.workdays.split(',').map(d=>weeks[d-1]).shift()}至${detail.workdays.split(',').map(d=>weeks[d-1]).pop()} ${detail.workstarttime.substring(0, 5)}-${detail.workendtime.substring(0, 5)}${detail.worktimeremark && `(${detail.worktimeremark})`}`"></p>
                        <p class="icon-p address" v-text="detail.address"></p>
                    </div>
                </div>
                <p :class="{'intro': true, 'hide': !detail.intro}" v-text="detail.introduce"></p>
            </div>
            <div class="clinic-doctors">
                <div class="doctor-item" v-for="(doctor, index) in dataList" :key="index">
                    <router-link :to="`/doctor/${doctor.globaluserid}`" :class="{'icon-gender': true, 'man': doctor.sex==1}"></router-link>
                    <router-link :to="`/doctor/${doctor.globaluserid}`" :class="{'doctor-name': true, 'fill': doctor.isdisplayorder != true }" v-text="doctor.username"></router-link>
                    <div :class="{'booking-count': true, 'show': doctor.isdisplayorder == true}" v-text="`已約${doctor.totalordercount}次`"></div>
                    <div class="doctor-address-list">
                        <span class="doctor-address-item" v-text="detail.arealevel1name"></span>
                        <span class="doctor-address-item" v-text="detail.arealevel2name"></span>
                    </div>
                    <span @click="onBookingClick(doctor)" class="btn-booking">預約</span>
                </div>
            </div>
            <div class="load-more" v-if="isLoadMore">
				<div class="lds-default"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
				<span class="text">正在加载...</span>
			</div>
			<div class="load-finished" v-if="isLoadFinished">已全部加載</div>
		</div>
        <loading v-if="loading" class="loading"/>
    </van-pull-refresh>
</template>

<script>
    import Loading from '@/layouts/Loading.vue'
    import clinic_detail from '@/views/mixins/clinic_detail'
	export default {
        mixins: [clinic_detail],
        components: {
            Loading,
        },
        mounted: function(){
            this.onRefresh()
        },
        data(){
            return {
                pageNo: 1,
                pageSize: 10,
                loading: true,
                isRefresh: false,
                isLoadMore: false,
                isLoadFinished: false,
                keyword: '',
                categoryid: null,
                dataList: [], 
                maxPage: 0,
                weeks: [
                    '週一',
                    '週二',
                    '週三',
                    '週四',
                    '週五',
                    '週六',
                    '週日',
                ],



                detail: null,
            }
        },
        methods: {
            onRefresh: function(){
                this.loading = true
                this.$store.dispatch('getClinicDetail', {clinicid: this.$route.params.clinicId})
                .then(detail => {
                    this.detail = detail
                    this.getDoctors()
                })
                .catch(error => {
                    this.loading = false
                    this._toast.warning(this, error)
                })
            },
            getDoctors: function(){
                this.$store.dispatch('getDoctorsByClinic', {clinicid: this.detail.clinicid, pageNo: this.pageNo, pageSize: this.pageSize})
                .then((data)=>{
                    this.loading = false
					this.isRefresh = false
					this.dataList = (data && data.list) || []
					var total = (data && data.total) || 0
					if(total > 0) {
						this.maxPage = Math.floor((total + this.pageSize - 1)/this.pageSize)
						if(this.pageNo >= this.maxPage) {
							this.isLoadFinished = true
						}
					} else {
                        this.isLoadFinished = true
					}
                }).catch((error)=>{
                    this.loading = false
                    this.isLoadMore = false
                })
            },
            onBookingClick: function(doctor) {
                if(!this.$store.getters.clientId) {
                    this._toast.warning(this, '未登錄，請先登錄')
                    setTimeout(() => {
                        this.$router.push('/login')
                    }, 1500)
                    return
                }
                this.$router.push({name: 'bookingEdit', params: {'doctor': doctor}})
            },
			handleScroll (event) {
				if(this.$refs.listView.clientHeight < window.innerHeight) return
				if((window.innerHeight + window.pageYOffset) > this.$refs.listView.clientHeight) {
					// 加载更多
					if(!this.loading && !this.isLoadMore && this.pageNo < this.maxPage) {
						this.isLoadMore = true
						this.loading = true
						this.$store.dispatch('getDoctorsByClinic', {clinicid: this.$route.params.clinicId, pageNo: this.pageNo+1, pageSize: this.pageSize})
                        .then((data)=>{
							this.loading = false
							this.isLoadMore = false
							this.dataList = this.dataList.concat((data && data.list) || [])
                            this.pageNo = this.pageNo + 1
                            var total = (data && data.total) || 0
                            if(total > 0) {
                                var maxPage = Math.floor((total + this.pageSize - 1)/this.pageSize)
                                if(this.pageNo >= maxPage) {
                                    this.isLoadFinished = true
                                }
                            }
						}).catch((error)=>{
							this.loading = false
							this.isLoadMore = false
                        })
					}
				}
			},
        },
		created () {
			window.addEventListener('scroll', this.handleScroll);
		},
		destroyed () {
			window.removeEventListener('scroll', this.handleScroll);
        },
    }
</script>

<style lang="scss" scoped>
    #clinic-detail {
        background-color: #ededed;
        display: flex;
        flex-direction: column;
    }

    .booking-banner {
        display: block;
        background-image: url(../../../assets/imgs/mobile/doctor-list/img_banneryy@2x.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        height: 194px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    .booking-banner > .toolbar-title {
        display: inline-block;
        width: 334px;
        height: 91px;
        line-height: 91px;
        text-align: center;
        background-color: rgba(5, 27, 48, .4);
        font-size: 54px;
        font-weight: bold;
        color: #fff;
    }

    .clinic-info {
        display: flex;
        flex-direction: column;
    }

    .clinic-info .row {
        display: flex;
        flex-direction: row;
        background-color: #fff;
        padding: 24px 30px 35px 30px;
    }

    .clinic-info .icon-clinic {
        width: 80px;
        height: 80px;
        margin-right: 17px;
        object-fit: contain;
        position: relative;
        background-color: #f6f6f6;
    }

    .clinic-info .icon-clinic[src='']::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 80px;
        height: 80px;
        background-color: #fff;
        background-image: url(../../../assets/imgs/pc/img_moren@2x.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
    }

    .clinic-info .col {
        display: flex;
        flex-direction: column;
    }
    
    .clinic-info .name {
        font-size: 34px;
        line-height: 36px;
        font-weight: bold;
        color: #231F20;
    }

    .clinic-info .icon-p {
        line-height: 32px;
        font-weight: 400;
        color: #231F20;
        font-size: 28px;
        margin-top: 12px;
    }

    .clinic-info .icon-p.area {
        color: #969696;
        font-size: 24px;
        font-weight: 400;
        line-height: 24px;
        margin-bottom: 36px;
    }

    .clinic-info .icon-p.tel,
    .clinic-info .icon-p.email,
    .clinic-info .icon-p.address {
        position: relative;
        padding-left: 30px;
        margin-top: 4px;
    }

    .clinic-info .icon-p.tel::after,
    .clinic-info .icon-p.email::after,
    .clinic-info .icon-p.address::after {
        content: '';
        position: absolute;
        left: 0;
        top: 10px;
        width: 14px;
        height: 14px;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-image: url(../../../assets/imgs/pc/img_iphone@2x.png);
    }

    .clinic-info .icon-p.email::after {
        background-image: url(../../../assets/imgs/pc/img_time@2x.png);
        width: 14px;
        height: 11px;
    }

    .clinic-info .icon-p.address::after {
        background-image: url(../../../assets/imgs/pc/img_dib@2x.png);
        width: 11px;
        height: 14px;
    }

    .clinic-info .icon-p.address {
        color: #231F20;
        font-size: 26px;
        font-weight: 400;
    }

    .clinic-info .intro {
        font-weight: 400;
        font-size: 24px;
        color: #636363;
        margin-top: 16px;
        line-height: 36px;
        background-color: #fff;
        padding: 22px 24px 25px 24px;
    }
    
    .clinic-info .intro.hide {
        display: none;
    }

    .clinic-doctors {
        background-color: #fff;
        margin-top: 16px;
    }

    .clinic-doctors > .doctor-item {
        display: flex;
        flex-direction: row;
        position: relative;
        padding: 30px 0px;
        margin: 0 24px;
        border-bottom: 1px solid #f7f7f7;
    }

    .clinic-doctors > .doctor-item:last-child {
        margin-bottom: 20px;
    }

    .clinic-doctors > .doctor-item > .icon-gender {
        width: 80px;
        height: 80px;
        background-image: url(../../../assets/imgs/mobile/doctor-list/img_nvys@2x.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }

    .clinic-doctors > .doctor-item > .icon-gender.man {
        background-image: url(../../../assets/imgs/mobile/doctor-list/img_nanys@2x.png);
    }

    .clinic-doctors > .doctor-item > .doctor-address-list {
        position: absolute;
        display: flex;
        flex-direction: row;
        left: 96px;
        top: 87px;
        padding-left: 31px;
    }

    .clinic-doctors > .doctor-item > .doctor-address-list::before {
        content: '';
        width: 14px;
        height: 20px;
        top: 0;
        left: 0;
        position: absolute;
        background-image: url(../../../assets/imgs/mobile/doctor-list/icon_zuob@2x.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }

    .clinic-doctors > .doctor-item > .doctor-address-list > .doctor-address-item {
        color: #969696;
        font-size: 24px;
        line-height: 23px;
        font-weight: 400;
        margin-right: 23px;
    }

    .clinic-doctors > .doctor-item > .doctor-name { 
        color: #231F20;
        font-size: 34px;
        font-weight: bold;
        line-height: 32px;
        margin-left: 14px;
    }

    .clinic-doctors > .doctor-item > .doctor-name.fill {
        flex: 1;
    }

    .clinic-doctors > .doctor-item > .booking-count {
        flex: 1;
        color: #FF8000;
        font-size: 24px;
        font-weight: bold;
        line-height: 24px;
        margin-left: 36px;
        margin-top: 6px;
        display: none;
    }

    .clinic-doctors > .doctor-item > .booking-count.show {
        display: block;
    }

    .clinic-doctors > .doctor-item > .btn-booking {
        background-color: #FFE3D5;
        border-radius: 04px;
        width: 96px;
        line-height: 48px;
        height: 48px;
        text-align: center;
        font-size: 26px;
        color: #FF8000;
        font-weight: bold;
        margin-right: 24px;
    }

	// loadmore
	.load-more {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
	}

	.load-more > .text {
		margin-left: 24px;
	}

	.lds-default {
		display: inline-block;
		position: relative;
		width: 80px;
		height: 80px;
	}
	.lds-default div {
		position: absolute;
		width: 6px;
		height: 6px;
		background: #36C4D0;
		border-radius: 50%;
		animation: lds-default 1.2s linear infinite;
	}
	.lds-default div:nth-child(1) {
		animation-delay: 0s;
		top: 37px;
		left: 66px;
	}
	.lds-default div:nth-child(2) {
		animation-delay: -0.1s;
		top: 22px;
		left: 62px;
	}
	.lds-default div:nth-child(3) {
		animation-delay: -0.2s;
		top: 11px;
		left: 52px;
	}
	.lds-default div:nth-child(4) {
		animation-delay: -0.3s;
		top: 7px;
		left: 37px;
	}
	.lds-default div:nth-child(5) {
		animation-delay: -0.4s;
		top: 11px;
		left: 22px;
	}
	.lds-default div:nth-child(6) {
		animation-delay: -0.5s;
		top: 22px;
		left: 11px;
	}
	.lds-default div:nth-child(7) {
		animation-delay: -0.6s;
		top: 37px;
		left: 7px;
	}
	.lds-default div:nth-child(8) {
		animation-delay: -0.7s;
		top: 52px;
		left: 11px;
	}
	.lds-default div:nth-child(9) {
		animation-delay: -0.8s;
		top: 62px;
		left: 22px;
	}
	.lds-default div:nth-child(10) {
		animation-delay: -0.9s;
		top: 66px;
		left: 37px;
	}
	.lds-default div:nth-child(11) {
		animation-delay: -1s;
		top: 62px;
		left: 52px;
	}
	.lds-default div:nth-child(12) {
		animation-delay: -1.1s;
		top: 52px;
		left: 62px;
	}
	@keyframes lds-default {
		0%, 20%, 80%, 100% {
			transform: scale(1);
		}
		50% {
			transform: scale(1.5);
		}
	}


	.load-finished {
		height: 44px;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		position: relative;
		font-size: 24px;
		font-weight: 400;
		color: #99999991;
	}

	.load-finished::before {
		content: '';
		width: 120px;
		height: 1px;
		background-color: #99999991;
		right: -130px;
		margin-right: 20px;
		top: 50%;
		transform: translateY(-50%);
	}

	.load-finished::after {
		content: '';
		width: 120px;
		height: 1px;
		background-color: #99999991;
		left: -130px;
		top: 50%;
		margin-left: 20px;
		transform: translateY(-50%);
	}
</style>
